<template>
  <div v-if="fullPage">
    <user-add
      :is-sidebar-active.sync="isAddNewUserSidebarActive"
      :is-readonly.sync="isAddNewUserSidebarInDisplayMode"
      :is-expanded.sync="isExpanded"
      :user-type-options="userTypeOptions"
      :auth-method-options="authMethodOptions"
      :value.sync="activeRecord"
      :edit-tab="editRecord"
      @refetch="fetchUsers"
    />
    <ImportCsvModal
      entity="user"
      title="Import Users"
      @import-success="onImportSuccess"
    />

    <!-- Table Container Card -->
    <div no-body class="container-card">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <transition name="slide-fade">
                <entity-filter
                  entity="user"
                  entity-plural-slug="people"
                  :entity-field-suggestions="USER_ENTITY_FIELD_SUGGESTIONS"
                  :search-query.sync="searchQuery"
                  :selected-queries.sync="selectedQueries"
                />
              </transition>

              <transition name="slide-fade">
                <div v-if="selectedItems.length !== 0">
                  <!-- Delete Selected Items Button -->
                  <b-button
                    class="ml-1"
                    variant="danger"
                    @click="deleteSelectedRecords"
                  >
                    <span class="text-nowrap">Delete</span>
                  </b-button>
                </div>
              </transition>

              <b-button
                v-if="$can('create', 'user')"
                variant="primary"
                style="margin-left: 20px"
                @click="editRecord(null)"
              >
                <span class="text-nowrap">Add Person</span>
              </b-button>
              <b-button
                v-if="$can('import', 'user')"
                variant="primary"
                style="margin-left: 20px"
                @click="openImportCsvModal()"
              >
                <span class="text-nowrap">Import People</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :hover="true"
        :items="users.nodes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :no-local-sorting="true"
        :busy="isLoading"
        show-empty
        empty-text="No matching records found"
        @row-clicked="displayRecord"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-1">
            <b-spinner type="grow" variant="primary" label="Loading..." />
          </div>
        </template>

        <!-- Header: Select -->
        <template #head(select)="">
          <div class="d-flex">
            <b-form-checkbox
              style="text-align: center !important; margin-left: 5px"
              :checked="
                users.nodes.length !== 0 &&
                selectedItems.length !== 0 &&
                users.nodes.length - 1 === selectedItems.length
              "
              @change="selectOrUnselectAllRows($event)"
            />
          </div>
        </template>

        <!-- Column: Select -->
        <template #cell(select)="data">
          <div v-if="data.item.roleIds.length == 0">
            <b-form-checkbox
              v-if="data.item.id !== currentUser.id"
              :checked="
                selectedItems.find((e) => e.id === data.item.id) ? true : false
              "
              style="text-align: center !important; margin-left: 5px"
              @change="selectOrUnselectRow($event, data.item)"
            />
            <div v-else style="text-align: center !important">
              <feather-icon icon="UserIcon" />
            </div>
          </div>
          <div v-else style="text-align: center !important">
            <feather-icon :id="`select-${data.item.id}`" icon="Link2Icon" />
            <b-tooltip
              title="related to roles"
              class="cursor-pointer"
              :target="`select-${data.item.id}`"
            />
          </div>
        </template>

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center" class="align-items-center">
            <template #aside>
              <b-avatar
                size="32"
                class="cursor-pointer"
                :text="data.item.name | avatarText"
                :variant="data.item.userType | resolveUserTypeVariant('light')"
                @click.native="displayRecord(data.item)"
              />
            </template>
            <span
              :style="{ overflow: 'hidden', textOverflow: 'ellipsis' }"
              class="font-weight-bold d-block text-nowrap name-width"
              @click="displayRecord(data.item)"
            >
              {{ data.item.name }}
            </span>
          </b-media>
        </template>

        <!-- Column: Roles -->
        <!-- <template #cell(roles)="data">
          <span
            v-for="(role, index) in data.item.roles"
            :key="role.id"
          >
            <span>{{ role.roleHandle }}</span>
            <span v-if="index < data.item.roles.length - 1">, </span>
          </span>
        </template> -->
        <template #cell(roles)="data">
          <span v-for="item in data.item.roles" :key="item.id">
            <b-badge pill variant="light-success" class="mr-1">
              {{ item.roleHandle }}
            </b-badge>
            <!-- <b-badge
              v-if="count <= 2"
              pill
              variant="light-success"
              class="mr-1"
            >
              {{ item.label }}
            </b-badge> -->
            <!-- <b-badge
              v-show="count > 2 && count < 4"
              pill
              variant="light-success"
              class="mr-1"
            >
              +{{ data.item.labels.length - count }}
            </b-badge> -->
          </span>
        </template>

        <!-- Column: UserType -->
        <template #cell(userType)="data">
          <div class="text-wrap">
            <feather-icon
              :icon="data.item.userType | resolveUserTypeIcon"
              size="18"
              class="mr-50"
              :class="data.item.userType | resolveUserTypeVariant('text')"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.userType
            }}</span>
          </div>
        </template>

        <!-- Column: Email -->
        <template #cell(peopleHandle)="data">
          <span>
            <span>{{ data.item.peopleHandle }}</span>
          </span>
        </template>

        <!-- Column: Auth Status -->
        <template #cell(isAuthEnabled)="data">
          <b-badge
            :variant="
              data.item.isAuthEnabled ? 'light-success' : 'light-danger'
            "
          >
            {{ data.item.isAuthEnabled ? "Enabled" : "Disabled" }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="action-buttons">
            <b-button
              :id="`row-${data.item.id}-view-task-icon`"
              variant="flat-warning"
              class="btn-icon rounded-circle"
              :href="getViewTasksRedirectUrl(data.item)"
              target="_blank"
            >
              <feather-icon icon="CheckSquareIcon" size="16" />
              <b-tooltip
                title="View Tasks"
                class="cursor-pointer"
                :target="`row-${data.item.id}-view-task-icon`"
              />
            </b-button>
            <b-button
              v-if="$can('read', 'user')"
              :id="`row-${data.item.id}-read-icon`"
              variant="flat-info"
              class="btn-icon rounded-circle"
              @click="displayRecord(data.item, true)"
            >
              <feather-icon icon="MaximizeIcon" size="16" />
              <b-tooltip
                title="Full Page View"
                class="cursor-pointer"
                :target="`row-${data.item.id}-read-icon`"
              />
            </b-button>

            <b-button
              v-if="$can('edit', 'user')"
              :id="`row-${data.item.id}-edit-icon`"
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="editRecord(data.item)"
            >
              <feather-icon icon="EditIcon" size="16" />
              <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`row-${data.item.id}-edit-icon`"
              />
            </b-button>

            <b-button
              v-if="$can('delete', 'user') && data.item.id !== currentUser.id"
              :id="`row-${data.item.id}-delete-icon`"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteRecord(data.item)"
            >
              <feather-icon icon="TrashIcon" size="16" />
              <b-tooltip
                title="Delete"
                class="cursor-pointer"
                :target="`row-${data.item.id}-delete-icon`"
              />
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="3"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ paginationMeta.from }} to {{ paginationMeta.to }} of
              {{ paginationMeta.of }} entries</span
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="5"
            sm="5"
            class="d-flex align-items-center justify-content-center justify-content-sm-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="users.totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="4"
            sm="4"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              append-to-body
              :calculate-position="withPopper"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BPagination,
  BTooltip,
  BSpinner,
  BFormCheckbox,
  VBTooltip,
  BBadge,
} from "bootstrap-vue"
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive"
import { debounce } from "vue-debounce"
import { mapState, mapActions } from "vuex"
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter"
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue"
// import UserListFilters from './UserListFilters.vue'
import ImportCsvModal from "@/views/shared/ImportCsvModal.vue"
import { USER_ENTITY_FIELD_SUGGESTIONS } from "@/utils/entity-filter"
import EntityFilter from "@/views/shared/Filter.vue"
import { selectPosition } from "../../@core/mixins/ui/select"
import UserAdd from "./UserAdd.vue"

export default {
  components: {
    // custom components
    UserAdd,
    ImportCsvModal,
    EntityFilter,

    // Bootstrap components
    // BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BSpinner,
    BFormCheckbox,
    BBadge,
    // other components
    vSelect,
  },
  filters: {
    avatarText,
    resolveUserTypeVariant: (userType, prefix) => {
      if (userType === "User") return `${prefix}-primary`
      if (userType === "Manager") return `${prefix}-info`
      if (userType === "Admin") return `${prefix}-danger`
      return `${prefix}-primary`
    },
    resolveUserTypeIcon: (userType) => {
      if (userType === "User") return "UserIcon"
      if (userType === "Manager") return "Edit2Icon"
      if (userType === "Admin") return "ServerIcon"
      return "UserIcon"
    },
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [selectPosition],
  data() {
    return {
      tableColumns: [
        {
          key: "select",
          stickyColumn: true,
          label: "select",
          class: "select-user-edit",
        },
        { key: "name", sortable: true, class: "my-user-class edit-head" },
        {
          key: "peopleHandle",
          sortable: true,
          label: "Email",
          class: "my-scoped-class edit-head",
        },
        {
          key: "roles",
          sortable: true,
          label: "Roles",
          class: "my-scoped-class roles-col",
        },
        {
          key: "userType",
          sortable: true,
          label: "User Type",
          class: "my-type-class edit-head",
        },
        {
          key: "isAuthEnabled",
          sortable: true,
          label: "Auth Status",
          class: "my-scoped-class edit-head",
        },
        { key: "actions", class: "my-scoped-class action-btn-edit" },
      ],
      userTypeOptions: [
        { label: "Admin", value: "Admin" },
        { label: "Manager", value: "Manager" },
        { label: "User", value: "User" },
      ],
      authMethodOptions: [
        { label: "Email / Password", value: "Email" },
        { label: "Provider (e.g., Google, Microsoft)", value: "Oauth" },
        { label: "No Auth", value: "No" },
      ],
      fieldSuggestions: [
        {
          label: "Name",
          value: "name",
          functionality: "field",
          allowedOperators: ["equal", "notEqual"],
        },
        {
          label: "Handle",
          value: "peopleHandle",
          functionality: "field",
          allowedOperators: ["equal", "notEqual"],
        },
        {
          label: "User Type",
          value: "userType",
          functionality: "field",
          allowedOperators: ["equal", "notEqual"],
        },
        {
          label: "Authentication Method",
          value: "authMethod",
          functionality: "field",
          allowedOperators: ["equal", "notEqual"],
        },
        {
          label: "Role",
          value: "roles",
          functionality: "relation",
          allowedOperators: ["equal", "notEqual"],
        },
      ],
      operatorSuggestions: [
        {
          label: "Equal",
          value: "equal",
          operation: "=",
          functionality: "operator",
        },
        {
          label: "Not Equal",
          value: "notEqual",
          operation: "!=",
          functionality: "operator",
        },
        {
          label: "Has",
          value: "has",
          functionality: "operator",
        },
      ],
      isAddNewUserSidebarActive: false,
      isAddNewUserSidebarInDisplayMode: false,
      activeRecord: null,
      searchFilter: {
        userType: null,
      },
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
      currentPage: 1,
      sortBy: "name",
      isSortDirDesc: false,
      allUsers: null,

      //  For filter feature
      USER_ENTITY_FIELD_SUGGESTIONS,
      searchQuery: [],
      selectedQueries: [],
      fullPage: true,

      // For selecting table rows
      selectedItems: [],
      isExpanded: false,

      // for delete modal
      userToDelete: {},

      isAuthEnabledOptions: [
        { text: "Enabled", value: true },
        { text: "Disabled", value: false },
      ],
      entityFields: [
        // ... other fields ...
        { text: "Authentication Status", value: "isAuthEnabled" },
      ],
    }
  },
  computed: {
    ...mapState("user", [
      "users",
      "isLoading",
      "fieldValues",
      "currentUser",
      "userInfo",
    ]),
    ...mapState("filter", ["filtersAccessibleToCurrentUser"]),
    paginationMeta() {
      return {
        from:
          this.perPage * (this.currentPage - 1) +
          (this.users.nodes.length ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.users.nodes.length,
        of: this.users.totalCount,
      }
    },
    queryParams() {
      return {
        userType: this.searchFilter.userType,
        q: this.searchQuery,
        offset: this.perPage * (this.currentPage - 1),
        limit: this.perPage,
        sortBy: this.sortBy,
        isDesc: this.isSortDirDesc,
      }
    },
    isSelectAllChecked: {
      get() {
        return this.users.nodes.length - 1 === this.selectedItems.length
      },
      set(newValue) {
        this.selectOrUnselectAllRows(newValue)
      },
    },
  },
  watch: {
    queryParams: {
      deep: true,
      handler() {
        this.fetchUsers()
      },
    },
    users: {
      handler() {
        const { compact } = this.$route.query
        if (compact && this.users.nodes[0]) {
          this.displayRecord(this.users.nodes[0], 0)
        }
      },
    },
    userInfo() {
      if (this.userInfo) {
        const data = this.userInfo.data.userGetInfo
        this.deleteFactory(data)
      }
    },
  },
  beforeCreate() {
    this.$store.dispatch("user/resetUsers")
  },
  created() {
    this.onLoad()
  },
  methods: {
    ...mapActions("user", ["getUsers", "getUsersStatsItems", "UserGetInfo"]),
    // ...mapActions('filter', ['getFiltersAccessibleToCurrentUser']),
    refetchData: debounce(function cb() {
      this.getUsers(this.queryParams)
    }, 300),
    onLoad() {
      let assignQuery = []
      const { q } = this.$route.query

      if (q) {
        if (Array.isArray(q)) {
          assignQuery = q
        } else {
          assignQuery = [q]
        }

        this.searchQuery = assignQuery
        this.selectedQueries = assignQuery
      }

      this.fetchUsers()
    },
    fetchUsers() {
      this.getUsers(this.queryParams)
    },
    editRecord(activeRecord) {
      this.activeRecord = activeRecord
      this.isAddNewUserSidebarActive = true
      this.isAddNewUserSidebarInDisplayMode = false
    },
    displayProfile(item) {
      this.$router.push({ path: `/userProfile/${item.id}` })
    },
    displayRecord(activeRecord, expand) {
      if (typeof expand === "number") {
        this.isExpanded = false
      } else {
        this.isExpanded = true
      }
      this.activeRecord = activeRecord
      this.isAddNewUserSidebarActive = true
      this.isAddNewUserSidebarInDisplayMode = true
    },

    async deleteRecord(user) {
      this.userToDelete = user
      await this.$store.dispatch("user/UserGetInfo", user.id)
    },
    deleteFactory(user) {
      if (!user) {
        this.$toast({
          component: ToastificationContentVue,
          position: "top-right",
          props: {
            title:
              "Cannot delete this Person, since they have one or more of the following:",
            style: "white-space: pre-line;color: red;",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `
            <strong style="color:red">* Assigned Role.</strong> Reassign this Person's role(s) to other people. Or remove this person's role(s) from their profile.
            <strong style="color:red">* Active Tasks.</strong> These are uncompleted tasks that are in progress, late, or have been edited. Reassign this user's tasks to someone else.
          `,
          },
        })
      } else {
        this.$bvModal
          .msgBoxConfirm("Are you sure that you want to delete this user?", {
            title: "Please Confirm",
            size: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              const { id } = this.userToDelete
              this.$store.dispatch("user/removeUser", id)
            }
          })
      }
    },
    openImportCsvModal() {
      this.$nextTick(() => {
        this.$bvModal.show("modal-import-csv")
      })
    },
    // creating and saving filters
    openSaveFilterModal() {
      this.$nextTick(() => {
        this.$bvModal.show("modal-save-filter")
      })
    },

    // form the URL used to redirect to the tasks page when view tasks icon is clicked
    getViewTasksRedirectUrl(user) {
      return `tasks?q=assignee=${user.name}`
    },

    // for multi-select feature
    selectOrUnselectRow($event, record) {
      if ($event) {
        this.selectedItems.push(record)
      } else {
        const recordToDeleteIndex = this.selectedItems.findIndex(
          (e) => e.id === record.id
        )
        this.selectedItems.splice(recordToDeleteIndex, 1)
      }
    },
    selectAllRows() {
      const userNodesWithoutCurrentUser = this.users.nodes.filter(
        (item) => item.id !== this.currentUser.id
      )
      const items = userNodesWithoutCurrentUser.filter(
        (item) => item.roles.length === 0
      )
      this.selectedItems = [...items]
    },
    clearSelected() {
      this.selectedItems = []
    },
    selectOrUnselectAllRows(value) {
      if (value) this.selectAllRows()
      else this.clearSelected()
    },
    deleteSelectedRecords() {
      const selectedItemsIds = this.selectedItems.map((item) => item.id)
      this.$bvModal
        .msgBoxConfirm("Are you sure that you want to delete this user?", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store
              .dispatch("user/removeUsers", selectedItemsIds)
              .then(() => {
                this.selectedItems = []
              })
          }
        })
    },
    onImportSuccess() {
      this.getUsers(this.queryParams)
    },
  },
}
</script>

<style lang="scss">
@import "../../@core/scss/vue/libs/vue-select";
.per-page-selector {
  width: 90px;
}
.action-buttons {
  margin-left: -10px;
  button {
    margin-right: 4px;
  }
}

.container-card {
  overflow: auto;
  background-color: white;
  border: none;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: 0.428rem;
}

.name-width {
  min-width: 168px;
}

.action-btn-edit {
  white-space: pre-wrap !important;
  text-align: center !important;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  padding: 0px !important;
  div {
    text-align: center !important;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    margin: 0px !important;
  }
}

td.action-btn-edit {
  white-space: pre-wrap !important;
  text-align: center !important;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}
th.action-btn-edit {
  white-space: pre-wrap !important;
  text-align: center !important;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

td {
  padding: 0.72rem;
}
.select-user-edit {
  width: 50px !important;
  max-width: 50px !important;
  padding: 10.8px !important;
}
td.select-user-edit {
  @extend .select-user-edit;
}
th.select-user-edit {
  @extend .select-user-edit;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-scoped-class {
  @extend .truncate;
  width: 200;
  max-width: 200px;
  vertical-align: middle !important;
}
.my-user-class {
  @extend .truncate;
  max-width: 300px !important;
  min-width: 250px !important;
  vertical-align: middle !important;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.my-type-class {
  width: 150px;
  max-width: 150px;
  text-align: center;
}
@media screen and (max-width: 850px) {
  .my-type-class {
    text-align: center;
  }
}
th.my-type-class {
  @extend .my-type-class;
  vertical-align: middle !important;
}
/* th.edit-head{
    // padding-left: 5px;
  } */
.roles-col {
  max-width: fit-content !important;
  white-space: normal !important;

  span {
    margin: 2px 2px 0px 0px !important;
  }
}
</style>
